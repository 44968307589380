export const config = {
    API_URL: 'https://dev-api.omniflix.studio',
    RPC_URL: 'https://rpc.devnet-alpha.omniflix.network',
    REST_URL: 'https://api.devnet-alpha.omniflix.network',
    DATA_LAYER: 'https://data-layer-dev.omniflix.studio',
    FAUCET_URL: 'https://faucet-devnet-alpha.omniflix.network',
    CHAIN_ID: 'devnet-alpha-3',
    CHAIN_NAME: 'OmniFlix Hub Devnet',
    COIN_DENOM: 'FLIX',
    COIN_MINIMAL_DENOM: 'uflix',
    COIN_DECIMALS: 6,
    PREFIX: 'omniflix',
};

export const EXPLORER_URL = 'https://www.mintscan.io/omniflix';
export const MARKETPLACE_URL = 'https://nucleus-dev.omniflix.live';
export const TRANSACTION_SET_TIME_OUT = 3000;

export const chainId = config.CHAIN_ID;
export const chainName = config.CHAIN_NAME;
export const coinDenom = config.COIN_DENOM;
export const coinMinimalDenom = config.COIN_MINIMAL_DENOM;
export const coinDecimals = config.COIN_DECIMALS;
export const prefix = config.PREFIX;

export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 30;
export const DEFAULT_TOTAL = 40;
export const DEFAULT_LAZY_FETCH_HEIGHT = 256;
export const DEFAULT_SORT_BY = 'created_at';
export const DEFAULT_ORDER = 'desc';

export const GAS_PRICE_STEP_LOW = 0.001;
export const GAS_PRICE_STEP_AVERAGE = 0.0025;
export const GAS_PRICE_STEP_HIGH = 0.025;

export const gas = {
    LIST_NFT: 200000,
    MINT_NFT: 300000,
    ITC_CLAIM: 400000,
};

export const walletExtensions = {
    LEAP: 'https://chrome.google.com/webstore/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg',
    COSMO_STATION: 'https://chrome.google.com/webstore/detail/cosmostation-wallet/fpkhgmpbidmiogeglndfbkegfdlnajnf',
    KEPLR: 'https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap',
};

export const cosmoStationChainConfig = {
    chainId: chainId,
    chainName: chainName,
    addressPrefix: prefix,
    baseDenom: coinMinimalDenom,
    displayDenom: coinDenom,
    restURL: config.REST_URL,
    coinType: '118', // optional (default: '118')
    decimals: coinDecimals, // optional (default: 6)
    gasRate: {
        average: '0.0025',
        low: '0.001',
        tiny: '0.025',
    },
};

export const chainConfig = {
    chainId: chainId,
    chainName,
    rpc: config.RPC_URL,
    rest: config.REST_URL,
    stakeCurrency: {
        coinDenom,
        coinMinimalDenom,
        coinDecimals,
    },
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: `${prefix}`,
        bech32PrefixAccPub: `${prefix}pub`,
        bech32PrefixValAddr: `${prefix}valoper`,
        bech32PrefixValPub: `${prefix}valoperpub`,
        bech32PrefixConsAddr: `${prefix}valcons`,
        bech32PrefixConsPub: `${prefix}valconspub`,
    },
    currencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
        },
    ],
    feeCurrencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
            gasPriceStep: {
                low: 0.01,
                average: 0.025,
                high: 0.04,
            },
        },
    ],
    coinType: 118,
};
